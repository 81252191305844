'use client';
import Loading from '@/components/common/Loading';
import AppLogo from '@/components/common/AppLogo';
import { ProGloveDisplayTemplateId, useProGlove } from '@/components/proglove/ProgloveProvider';
import { apiSdkAtom } from '@/context/atoms/ApiSdk';
import { authenticationAtom } from '@/context/atoms/Authentication';
import { jwtAuthMechanismAtom } from '@/context/atoms/JWTAuthMechanism';
import { anyNodePermissions, userPermissionsAtom } from '@/context/atoms/UserPermissions';
import useSingleSnackbar from '@/helper/useSingleSnackbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';

export const dynamic = 'force-dynamic';

export default function LogIn(): React.JSX.Element {
  const [auth] = useAtom(authenticationAtom);
  const [authMechanism] = useAtom(jwtAuthMechanismAtom);
  const [api] = useAtom(apiSdkAtom);
  const [, setAuthentication] = useAtom(authenticationAtom);
  const [, setAnyNodePermissions] = useAtom(userPermissionsAtom);
  const { enqueueSnackbar } = useSingleSnackbar();
  const [refInput, setRefInput] = useState<HTMLInputElement | null>(null);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const proGloveDisplayUpdate = useRef<boolean>(false);
  const { updateDisplay } = useProGlove();

  useEffect(() => {
    if (!proGloveDisplayUpdate.current) {
      updateDisplay({
        display_template_id: ProGloveDisplayTemplateId.PG1,
        display_fields: [
          {
            display_field_id: 1,
            display_field_header: 'ekko',
            display_field_text: 'Please login to continue',
          },
        ],
      });
      proGloveDisplayUpdate.current = true;
    }

    return () => {
      proGloveDisplayUpdate.current = false;
    };
  }, [updateDisplay]);

  useEffect(() => {
    refInput?.focus();
  }, [refInput]);

  const authCallback = async (): Promise<void> => {
    await api.isAuthenticated();
    if (authMechanism.authentication.user?.permissions !== undefined) {
      setAnyNodePermissions(anyNodePermissions(authMechanism.authentication.user.permissions));
    }
    setAuthentication(authMechanism.authentication);
    setSubmitDisabled(false);
  };

  const loginApiCall = async (credentials: { username: string; password: string }): Promise<void> => {
    setSubmitDisabled(true);
    try {
      await api.authenticate(credentials, authCallback);
    } catch (e) {
      setSubmitDisabled(false);
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    if (typeof username === 'string' && typeof password === 'string') {
      const credentials = {
        username,
        password,
      };
      void loginApiCall(credentials);
    }
  };

  if (auth.isAuthenticated) {
    return <Loading size={150} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AppLogo my={3} />
        <Box component="form" action={'dialog'} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            inputRef={setRefInput}
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button disabled={submitDisabled} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
